import React from 'react'

import { YStack, XStack } from 'tamagui'

import { Text, Paragraph } from './Text'
import { ExternalLink, Link } from './Link'
import { Icon } from './Icon'

export const Footer = () => {
  return (
    <YStack
      backgroundColor='$grey3'
      paddingVertical='$4'
      paddingHorizontal='$5'
      width='100%'
      marginLeft='auto'
      marginRight='auto'
      $gtXs={{
        borderRadius: '$5',
        width: 392,
        marginBottom: 46,
      }}
      data-nosnippet
    >
      <XStack justifyContent='space-between'>
        <YStack justifyContent='space-between'>
          <Paragraph size='$4' fontWeight='$7' color='$grey7'>
            Guild
          </Paragraph>

          <XStack alignItems='center' gap='$2'>
            <ExternalLink
              href='https://www.youtube.com/@guild_host'
              lineHeight='$3'
              aria-label={`Link to Guild's YouTube`}
            >
              <Icon name='youtube' size='$4' color='$grey6' />
            </ExternalLink>
            <ExternalLink
              href='https://twitter.com/guild__host'
              lineHeight='$3'
              aria-label={`Link to Guild's Twitter`}
            >
              <Icon name='twitter' size='$4' color='$grey6' />
            </ExternalLink>
            <ExternalLink
              href='https://www.linkedin.com/company/guild-host'
              lineHeight='$3'
              aria-label={`Link to Guild's LinkedIn`}
            >
              <Icon name='linkedin' size='$4' color='$grey6' />
            </ExternalLink>
          </XStack>
        </YStack>

        <YStack justifyContent='space-between'>
          <Paragraph fontWeight='$5' color='$grey7' size='$4' textAlign='right'>
            Get in touch!
          </Paragraph>

          <span className='notranslate'>
            <ExternalLink href='mailto:hi@guild.host' size='$4' color='$grey7'>
              hi@guild.host
            </ExternalLink>
          </span>
        </YStack>
      </XStack>
    </YStack>
  )
}
